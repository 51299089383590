import React, { FC } from 'react';

import GatsbyImage from 'common/GatsbyImage';

import { IFeatures } from '../model';

const Features: FC<IFeatures> = ({ features }) => (
  <ul className="home-banner__features">
    {features.map(({ properties: { text, image } }) => (
      <li key={text} className="home-banner__feature-item">
        <GatsbyImage
          className="home-banner__feature-item--image"
          image={image?.[0].properties.image}
          alt={image?.[0].properties.imageAlt}
          objectFit="contain"
        />
        <p className="home-banner__feature-item--text">{text}</p>
      </li>
    ))}
  </ul>
);

export default Features;
