import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import GatsbyImage from 'common/GatsbyImage';
import useMatchMedia from 'hooks/useMatchMedia';

import { IFeatureBannerProps } from './model';

import './FeatureBanner.scss';

const FeatureBanner: FC<IFeatureBannerProps> = ({
  title,
  description,
  features,
  image,
  background,
  mobileBackground,
  secondaryTitle,
  secondaryImage,
  bannerClass,
}) => {
  const isDesktop = useMatchMedia('(min-width: 992px)');

  const backgroundImage = isDesktop
    ? background?.[0]?.properties
    : mobileBackground?.[0]?.properties;

  return (
    <section
      className={classNames('feature-banner', {
        [`feature-banner--${bannerClass}`]: bannerClass,
      })}
    >
      <div className="feature-banner__container">
        <div className="feature-banner__main-block">
          <div className="feature-banner__text">
            <DangerouslySetInnerHtml html={title} className="feature-banner__title" />
            <DangerouslySetInnerHtml html={description} className="feature-banner__description" />
          </div>

          <ul className="feature-banner__features">
            {features.map(({ properties: { text, image: featureImage } }) => (
              <li
                key={featureImage[0].properties.image.fallbackUrl}
                className="feature-banner__feature-item"
              >
                <GatsbyImage
                  className="feature-banner__feature-item--image"
                  image={featureImage[0].properties.image}
                  alt={featureImage[0].properties.imageAlt}
                  objectFit="contain"
                />
                <DangerouslySetInnerHtml
                  html={text}
                  className="feature-banner__feature-item--text"
                />
              </li>
            ))}
          </ul>

          <GatsbyImage
            image={image[0].properties.image}
            alt={image[0].properties.imageAlt}
            objectFit="contain"
            className="feature-banner__image"
            isLazyLoading={false}
          />
        </div>

        {secondaryTitle && secondaryImage?.[0]?.properties ? (
          <div className="feature-banner__extra-block">
            <DangerouslySetInnerHtml
              html={secondaryTitle}
              className="feature-banner__extra-title"
            />
            <GatsbyImage
              image={secondaryImage[0].properties.image}
              alt={secondaryImage[0].properties.imageAlt}
              objectFit="cover"
              className="feature-banner__extra-image"
              objectPosition="right bottom"
              isLazyLoading={false}
            />
          </div>
        ) : null}
      </div>
      {backgroundImage ? (
        <div className="feature-banner__background">
          <GatsbyImage
            image={backgroundImage.image}
            alt={backgroundImage.imageAlt}
            objectFit="cover"
            objectPosition="45% top"
            isLazyLoading={false}
          />
        </div>
      ) : null}
    </section>
  );
};

export const query = graphql`
  fragment FragmentFeatureBanner on TFeatureBanner {
    properties {
      title
      description
      features {
        properties {
          text
          image {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 120) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imageAlt
            }
          }
        }
      }
      image {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 570, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      background {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 2200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      mobileBackground {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      secondaryTitle
      secondaryImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1060, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
    }
    structure
  }
`;
export default FeatureBanner;
