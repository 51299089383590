import { useState, useEffect } from 'react';

import { UseMatchMedia } from './model';

const useMatchMedia: UseMatchMedia = (condition: string) => {
  const [isMatched, setIsMatched] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia(condition);
    const updateIsMatched = () => setIsMatched(mediaQuery.matches);
    updateIsMatched();
    mediaQuery.addListener(updateIsMatched);

    return () => mediaQuery.removeListener(updateIsMatched);
  }, []);

  return isMatched;
};

export default useMatchMedia;
