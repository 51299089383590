import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import getFamily from 'utils/getFamily';
import { Container, DangerouslySetInnerHtml } from 'layout';
import GatsbyImage from 'common/GatsbyImage';
import { UmbracoSlimBanner } from '@shared/types/umbraco/compositions';
import './SlimBanner.scss';

const SlimBanner: FC<UmbracoSlimBanner.IData> = ({
  description,
  image,
  color,
  title,
  tags,
  sectionTheme,
  isBigLogoStyle,
}) => {
  const family = getFamily(tags);
  const bgColor = color?.label || family?.color?.label;
  const isDark = bgColor
    ? !['grey', 'ultimate-21', 'quantum-silver', 'black-squeeze'].includes(bgColor)
    : false;

  return (
    <div
      className={classNames('slim-banner', {
        [`slim-banner--bg-${bgColor}`]: bgColor,
        'slim-banner--dark-theme': isDark,
        [`slim-banner--${sectionTheme}`]: sectionTheme,
        'slim-banner--big-logo': isBigLogoStyle,
      })}
      data-testid="slim-banner"
    >
      <Container fluid>
        <div className="slim-banner__flex">
          {image?.length ? (
            <div className="slim-banner__image">
              <GatsbyImage
                image={image[0].properties.image}
                objectFit="contain"
                className="slim-banner__image-img"
                alt={image[0].properties.imageAlt}
                isLazyLoading={false}
                fadeIn={false}
              />
            </div>
          ) : null}
          {title ? <DangerouslySetInnerHtml element="h2" className="sr-only" html={title} /> : null}
          {description ? (
            <DangerouslySetInnerHtml className="slim-banner__description" html={description} />
          ) : null}
        </div>
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentSlimBanner on TSlimbanner {
    properties {
      image {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 280) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      description
      color {
        label
        value
      }
      title
      sectionTheme
      isBigLogoStyle
    }
    structure
  }
`;

export default SlimBanner;
