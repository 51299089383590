import React, { FC } from 'react';
import { IAutoplayBgVideoProps } from './model';

/**
 * https://github.com/facebook/react/issues/10389
 */

const AutoplayBgVideo: FC<IAutoplayBgVideoProps> = ({ mainVideo }) => (
  <div
    className="video-background-section__video"
    dangerouslySetInnerHTML={{
      __html: `
      <div class="video-background-section__overflow"></div>
        <video
          loop
          muted
          autoplay
          playsinline
          preload="metadata"
        >
        <source src="${mainVideo}" type="video/mp4"></source>
      </video>`,
    }}
  />
);

export default AutoplayBgVideo;
